<script setup>
import { onMounted, reactive } from 'vue';
import JSON_DATA from './JSON/index.json';
import { useRouter } from 'vue-router';
import { state } from '@/store/store';
import axios from 'axios';
// 路由
const router = useRouter();

// 笔记列表数据
let copyNoteListData = reactive([]);

// 跳转详情
let gotoDetails = function (i) {
  router.push({ name: 'details', query: { url: i.url } });
};

// 获取随机图片
let getRandomImage = async function () {
  return await axios.get('https://api.vvhan.com/api/wallpaper/views?type=json');
};

// 获取笔记列表数据
let getNoteListData = function () {
  copyNoteListData.value = [];
  JSON_DATA.noteList.map(async (i) => {
    let res = await getRandomImage();
    i.imgUrl = res.data.url;
    copyNoteListData.value.push(i);
    // 进度条数值
    state.percentage += 100 / JSON_DATA.noteList.length;
  });
};

onMounted(() => {
  getNoteListData();
});
</script>

<template>
  <div class="main">
    <div v-for="item in copyNoteListData.value" :key="item.url" class="item">
      <el-card shadow="hover" @click="gotoDetails(item)">
        <el-image style="width: 100%; height: 140px" :src="item.imgUrl" loading="lazy" fit="cover" />
        <div>
          <el-text style="font-size: 18px; font-weight: 800" truncated>
            {{ item.title }}
          </el-text>
          <el-text line-clamp="2">
            {{ item.introduce }}
          </el-text>
        </div>
      </el-card>
    </div>
  </div>
</template>

<style scoped lang="less">
.main {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 10px;

  .item {
    cursor: pointer;
  }
}
</style>
