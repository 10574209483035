import { createRouter, createMemoryHistory } from 'vue-router';
import codeBlog from '../pages/codeBlog';
import details from '../pages/codeBlog/details.vue';
import photoAlbum from '../pages/photoAlbum';
import { state } from '@/store/store';

const routes = [
  {
    name: 'photoAlbum',
    path: '/',
    component: photoAlbum,
    meta: {
      title: '照片集',
    },
  },
  {
    name: 'codeBlog',
    path: '/codeBlog',
    component: codeBlog,
    meta: {
      title: '代码博客',
    },
  },
  {
    name: 'details',
    path: '/details',
    component: details,
    meta: {
      title: '详情',
    },
  },
];

const router = createRouter({
  history: createMemoryHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  state.percentage = 0;
  next();
});

router.afterEach((to, from, next) => {
  document.title = to.meta.title;
});

export default router;
