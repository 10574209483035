<template>
  <Layout></Layout>
</template>

<script>
import Layout from '../src/components/Layout';

export default {
  name: 'App',
  components: { Layout },
};
</script>

<style>
/* 全局颜色变量 */
:root {
  --titleColor1: #01579b;
  --titleColor2: #00838f;
  --titleColor3: #ffd600;
  --titleColor4: #ff4081;
  --titleColor5: #f44336;
}

body {
  background-color: #f0f1f3;
}

/* 文字不可复制 */
.no-copy {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* 标准语法 */
}
</style>
