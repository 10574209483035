<script setup>
import JSON_DATA from './JSON/index.json';
import { Waterfall, LazyImg } from 'vue-waterfall-plugin-next';
import 'vue-waterfall-plugin-next/dist/style.css';
import { computed, ref, reactive, onMounted } from 'vue';
import { state } from '@/store/store';
import { ElMessageBox } from 'element-plus';

// 图片数据
let imgData = computed(() => {
  let arr = [];
  JSON_DATA.images.forEach((item) => {
    item.dataArr.forEach((is) => arr.push(is));
  });
  return arr;
});

let imageViewerShow = ref(false);
let srcList = reactive([]);
let imgIndex = ref(0);

// 图片查阅弹窗显示
let viewTheImage = function (index) {
  imgIndex = index;
  srcList = [...imgData.value.map((i) => i.src)];
  imageViewerShow.value = true;
};

// 图片查阅弹窗关闭回调
let closeImageViewer = function () {
  imageViewerShow.value = false;
  srcList = [];
};

onMounted(() => {
  state.percentage = 100;

  ElMessageBox.confirm('图片较大，加载慢，请见谅。。。', '', {
    showCancelButton: false,
    confirmButtonText: '好的',
  }).then(() => {});
});
</script>

<template>
  <div>
    <Waterfall :list="imgData" :width="420">
      <template #default="{ item, url, index }">
        <el-card @click="viewTheImage(index)" style="cursor: pointer" shadow="hover">
          <LazyImg :url="url" />
        </el-card>
      </template>
    </Waterfall>
    <el-image-viewer @close="closeImageViewer" :url-list="srcList" :initialIndex="imgIndex" v-if="imageViewerShow" />
  </div>
</template>

<style scoped lang="less"></style>
