<script setup>
import { onMounted, reactive, ref, nextTick, getCurrentInstance } from 'vue';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { state } from '@/store/store';

// 路由
const router = useRouter();
const route = useRoute();

// md内容
let mdText = ref('');
// md实例
let previewCVM = ref();
// 标题锚点
let titles = reactive([]);
// 滚动的DOM
let scrollContainerDom = ref();

let handleAnchorClick = (anchor) => {
  const { lineIndex } = anchor;
  const heading = previewCVM.value.$el.querySelector(`[data-v-md-line="${lineIndex}"]`);
  if (heading) {
    previewCVM.value.scrollToTarget({
      target: heading,
      scrollContainer: scrollContainerDom.value,
      top: 60,
    });
  }
};

onMounted(() => {
  scrollContainerDom.value = getCurrentInstance().parent.parent.proxy.$el;
  axios.get(`https://happylwy108.cn/mdFiles/${route.query.url}`).then((d) => {
    mdText.value = d.data;
    state.percentage = 100;
    nextTick(() => {
      const anchors = previewCVM.value.$el.querySelectorAll('h1,h2,h3,h4,h5,h6');
      const copyTitles = Array.from(anchors).filter((title) => !!title.innerText.trim());
      if (!copyTitles.length) {
        titles.value = [];
        return;
      }
      const hTags = Array.from(new Set(copyTitles.map((title) => title.tagName))).sort();
      titles.value = copyTitles.map((el) => ({
        title: el.innerText,
        lineIndex: el.getAttribute('data-v-md-line'),
        indent: hTags.indexOf(el.tagName),
      }));
    });
  });
});
</script>

<template>
  <div class="main">
    <div class="md-box shadow">
      <div style="flex: 1; padding-left: 10px" class="wrap-left">
        <el-tooltip effect="dark" content="返回" placement="right">
          <el-button :icon="ArrowLeftBold" class="goBack_btn" size="large" circle @click="router.go(-1)" />
        </el-tooltip>
        <div style="position: sticky; top: 100px; padding: 0 10px; border-radius: 4px; min-width: 240px;" class="shadow">
          <div
            v-for="anchor in titles.value"
            :style="{ padding: `10px 0 10px ${anchor.indent * 20}px` }"
            @click="handleAnchorClick(anchor)"
          >
            <span style="cursor: pointer" class="text-primary-emphasis">#&nbsp;{{ anchor.title }}</span>
          </div>
        </div>
      </div>
      <v-md-preview :text="mdText" ref="previewCVM" style="flex: 4"></v-md-preview>
    </div>
  </div>
</template>

<style scoped lang="less">
.main {
  .md-box {
    width: 80%;
    margin: 0 auto;
    border-radius: 4px;
    display: flex;
  }

  @media (max-width: 768px) {
    .md-box {
      width: 100%;
    }
  }
}

.goBack_btn {
  position: sticky;
  top: 10px;
  font-size: 24px;
}

@media (max-width: 768px) {
  .wrap-left {
    display: none;
  }
}
</style>
